import React from "react";
import "./App.css";
import Form from "./components/Form";
import MarqbuzzFooter from "./components/MarqbuzzFooter";
import ThanksForSUBMISSIONAMIGOS from "./components/ThanksForSUBMISSIONAMIGOS";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  return (
    <div>
      {!isSubmitted ? (
        <Form setFormSubmitted={setIsSubmitted} />
      ) : (
        <ThanksForSUBMISSIONAMIGOS setFormSubmitted={setIsSubmitted} />
      )}
    </div>
  );
}
export default App;

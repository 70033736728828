import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";

function Form({
  setFormSubmitted,
}: {
  setFormSubmitted: (v: boolean) => void;
}) {
  const [budget, setBudget] = React.useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<form.FormProps>();

  React.useEffect(() => {
    const intent = getValues("intent");
    if (intent === "quote") {
      setBudget(true);
    } else {
      setBudget(false);
    } 
  }, [getValues("intent")]);

  const [loading, setLoading] = React.useState(false);
  const onSubmit = (data: form.FormProps) => {
    try {
      setLoading(true);
      axios
        .post("https://marqbuzz-backend.vercel.app/", { ...data })
        .then(() => {
          setLoading(false);
          setFormSubmitted(true);
        })
        .catch((err: unknown) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  };
  return (
    <section className="w-full font-Roboto max-w-7xl  mx-auto px-3 vsm:px-6 md:px-12">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex py-[60px] w-full flex-col items-start gap-[19px]"
      >
        <div className="flex w-[98px] h-[98px] py-[16.7px] px-[21.6px] justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="57"
            height="65"
            viewBox="0 0 57 65"
            fill="none"
          >
            <g clipPath="url(#clip0_1461_2901)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.74414 59.5725C13.9997 61.7364 29.2024 63.8747 40.6439 58.2564C41.866 57.6723 42.746 56.5963 43.1772 55.3252C44.2709 55.5045 45.3273 55.7035 46.3798 55.9132C54.8108 57.5939 54.3521 61.0954 49.0719 63.2544C43.7917 65.4123 26.1125 64.5852 19.2407 63.4572C18.1225 63.274 17.0935 63.1182 16.14 62.9741C11.3782 62.2558 8.4901 61.8197 5.74414 59.5725Z"
                fill="#FC361C"
              />
              <path
                d="M25.4885 63.4162C18.7442 63.4162 12.6309 62.4372 7.22526 60.486C4.83994 59.6295 3.82564 56.6199 3.68354 54.3032C3.44932 50.7918 3.0142 44.706 2.54184 38.0675C1.08098 17.5829 0.492003 7.18868 0.774897 6.88488L1.06498 6.57324H2.0832L2.35858 7.09264C3.23568 8.74296 13.8363 11.1851 23.5864 10.8725L24.3468 10.849C31.393 10.6304 44.5094 10.2228 45.3149 8.08538L45.5531 7.45132H47.27L47.2769 8.48816L44.6162 54.7461C44.0576 59.2669 39.6985 62.2049 35.8795 62.7067C32.2701 63.18 28.8029 63.4172 25.4876 63.4172L25.4885 63.4162ZM2.54674 9.61418C2.83192 14.5828 3.77468 27.7942 4.49596 37.9283C4.9693 44.5698 5.40442 50.6585 5.63962 54.1777C5.75036 55.9809 6.50202 58.1438 7.8897 58.6426C15.6562 61.4474 24.9878 62.1608 35.6257 60.7633C38.7587 60.3527 42.2505 57.9223 42.667 54.5697L45.1963 10.5511C41.7869 12.1328 34.3958 12.4993 24.4096 12.809L23.6501 12.8325C17.0517 13.0432 6.90774 11.8701 2.54772 9.61516L2.54674 9.61418Z"
                fill="#FC361C"
              />
              <path
                d="M46.2349 8.39628C45.9292 8.39628 45.6273 8.2532 45.4372 7.98566C45.4039 7.94156 41.2115 2.62016 24.4182 2.62016C4.59767 2.62016 2.51419 6.58328 2.51027 6.62346C2.44559 7.15952 1.95755 7.5378 1.41855 7.47704C0.880532 7.4104 0.499312 6.92138 0.564972 6.38434C0.681592 5.42786 2.38483 0.660156 24.4172 0.660156C42.4071 0.660156 46.8514 6.59406 47.0307 6.84592C47.3453 7.28594 47.2434 7.89844 46.8033 8.21302C46.6309 8.33552 46.4319 8.39628 46.2349 8.39628Z"
                fill="#FC361C"
              />
              <path
                d="M48.8741 42.6549C46.9474 42.6549 45.2785 42.0218 44.5298 41.7386L44.272 41.6426C43.7605 41.4671 43.4871 40.9095 43.6625 40.398C43.8379 39.8864 44.3965 39.611 44.9081 39.7884C44.9894 39.8158 45.0952 39.856 45.2246 39.905C46.3447 40.3303 49.323 41.4564 51.7837 39.8893C53.4105 38.8917 53.6565 36.9111 54.1034 32.1287C54.6199 26.5535 54.76 24.2378 53.0038 22.8961C50.5695 20.895 46.5927 21.482 45.8204 21.6202C45.2824 21.7182 44.7787 21.3605 44.6836 20.8274C44.5886 20.2942 44.9433 19.7856 45.4765 19.6906C46.772 19.4612 51.23 18.8977 54.222 21.3605C56.8827 23.391 56.5887 26.5594 56.0565 32.31C55.6067 37.113 55.3362 40.0118 52.8225 41.5514C51.5221 42.3795 50.1422 42.6539 48.8741 42.6539V42.6549Z"
                fill="#FC361C"
              />
              <path
                d="M43.3725 9.74529C43.3725 10.9615 33.0629 14.0014 24.2831 13.2919C9.11853 12.1776 5.39355 11.2653 5.39355 8.83389C5.39355 7.61771 15.6365 6.60439 24.2831 6.80725C33.1295 7.11105 43.4391 8.53009 43.3725 9.74529Z"
                fill="#FC361C"
              />
              <path
                d="M22.0819 42.4238C21.6252 42.4238 21.1695 42.266 20.804 41.9495C17.3181 38.9281 9.51437 31.4811 12.3162 27.569C13.7568 25.558 15.3591 24.5388 17.0809 24.5388H17.1015C18.8146 24.5476 20.5051 25.5531 22.1358 27.5327C23.3784 25.9863 25.1963 24.0586 27.4631 24.0586H27.4885C28.9703 24.0684 30.3482 24.9073 31.5849 26.5517C35.2217 31.387 28.496 37.3797 24.477 40.9597C24.0948 41.2997 23.743 41.6133 23.4343 41.8966C23.0501 42.2474 22.565 42.4238 22.0819 42.4238ZM13.9106 28.7087C12.7141 30.3796 16.1529 35.3237 22.0878 40.4677C22.4259 40.162 22.7845 39.8425 23.1726 39.4956C26.4576 36.5693 32.5708 31.1234 30.0189 27.7297C29.1692 26.5997 28.3137 26.0235 27.4758 26.0186H27.4631C25.7726 26.0186 24.037 28.2657 23.103 29.4741L22.0829 30.7853L21.3224 29.7014C20.3002 28.2452 18.7362 26.5076 17.0927 26.4988H17.0819C16.0274 26.4988 14.9602 27.2416 13.9106 28.7087Z"
                fill="#FC361C"
              />
            </g>
            <defs>
              <clipPath id="clip0_1461_2901">
                <rect
                  width="55.8571"
                  height="63.9391"
                  fill="white"
                  transform="translate(0.55957 0.660156)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="flex justify-between flex-col xl:flex-row items-start gap-12 xl:gap-[84px]  w-full">
          <div className="flex flex-col  items-start gap-5">
            <h5 className="flex h-20 font-Anton flex-col w-[17ch] justify-center text-[#FC361C] text-[35px] font-normal leading-[40.32px]">
              How can we help? Let's work together.
            </h5>
            <p className="text-[#6f6f71] mt-3 w-[37ch] text-base font-light leading-7">
              Working together takes some practice to get in sync, but once we
              find our rhythm, the result can be magical!
            </p>
          </div>
          <div className="flex w-full  py-2 flex-col items-start gap-2">
            <div className="flex flex-col items-start gap-[5px] w-full">
              <h3 className="flex h-5 flex-col justify-center w-full dark:text-[#212529] text-typo-900 text-base font-medium leading-4">
                What are you looking for?
              </h3>
              <p className="text-typo-600 flex h-5 text-[#6C757D] justify-center flex-col w-full text-base leading-4 font-light">
                Please choose an option below
              </p>
            </div>
            <div className="flex items-start justify-between gap-4 flex-col md:flex-row w-full">
              <label
                className="flex p-[17px] w-full rounded-lg border border-[#e8e8e8]  items-center gap-1 cursor-pointer"
                htmlFor="getaquote"
              >
                <input
                  {...register("intent", {
                    required: "This field is required",
                  })}
                  type="radio"
                  value={"quote"}
                  className="appearance-none quote-input-radio"
                  id="getaquote"
                />

                <span className="quote"></span>
                <div className="flex flex-col gap-1">
                  <h2 className="text-[#171717]  leading-4 text-[15px] font-normal">
                    Get a quote
                  </h2>
                  <p className="text-typo-600 text-base font-light leading-4">
                    For new projects
                  </p>
                </div>
              </label>
              <label
                className="flex p-[17px] w-full rounded-lg border border-[#e8e8e8]  items-center gap-1 cursor-pointer"
                htmlFor="talktous"
              >
                <input
                  {...register("intent", {
                    required: "please select an option",
                  })}
                  type="radio"
                  value={"talk"}
                  className="appearance-none quote-input-radio"
                  id="talktous"
                />
                <span className="quote"></span>
                <div className="flex flex-col gap-1">
                  <h2 className="text-[#171717] leading-4 text-[15px] font-normal">
                    Talk to us
                  </h2>
                  <p className="text-typo-600 text-base font-light leading-4">
                    Inquiry is free
                  </p>
                </div>
              </label>
              <label
                className="flex p-[17px] w-full rounded-lg border border-[#e8e8e8]  items-center gap-1 cursor-pointer"
                htmlFor="applyforrole"
              >
                <input
                  {...register("intent", {
                    required: "please select an option",
                  })}
                  type="radio"
                  value={"role"}
                  className="appearance-none quote-input-radio"
                  id="applyforrole"
                />
                <span className="quote quote-border"></span>
                <div className="flex flex-col gap-1">
                  <h2 className="text-[#171717]  leading-4 text-[15px] font-normal">
                    Apply for role
                  </h2>
                  <p className="text-typo-600 text-base font-light leading-4">
                    Be part of team
                  </p>
                </div>
              </label>
            </div>
            {errors.intent && (
              <p className="text-red-600">{errors.intent.message}</p>
            )}
          </div>
        </div>
        <div className="flex gap-8 flex-col items-start w-full">
          <div className="flex flex-col items-start gap-4 w-full">
            <div className="flex items-start w-full gap-4">
              <div className="w-full flex flex-col gap-2">
                <input
                  type="text"
                  placeholder="Name"
                  {...register("name", {
                    required: "please enter your name",
                    maxLength: 20,
                  })}
                  className="quote-input quote-border quote-border"
                />
                {errors.name && (
                  <p className="text-red-600">{errors.name.message}</p>
                )}
              </div>
              <div className="w-full flex flex-col gap-2">
                <input
                  {...register("email", {
                    required: "please enter your email address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address",
                    },
                  })}
                  type="email"
                  placeholder="Email address"
                  className="quote-input quote-border"
                />
                {errors.email && (
                  <p className="text-red-600">
                    please enter your email address
                  </p>
                )}
              </div>
            </div>
            <div className="w-full flex flex-col gap-2">
              <textarea
                {...register("message", {
                  required: "please enter a message",
                  maxLength: 100,
                })}
                placeholder="Message"
                rows={5}
                className="quote-input quote-border"
              />
              {errors.message && (
                <p className="text-red-600">{errors.message.message}</p>
              )}
            </div>
            {budget && (
              <div className="w-full flex flex-col gap-2">
                <select
                  {...register("budget", {
                    required: "please select a budget range",
                  })}
                  className="quote-input quote-border  appearance-none"
                >
                  <option selected disabled value={""}>
                    Budget range
                  </option>
                  <option value="10000">10000</option>
                  <option value="20000">20000</option>
                  <option value="30000">30000</option>
                  <option value="40000">40000</option>
                </select>
                {
                  <p className="text-red-600">
                    {errors.budget && errors.budget.message}
                  </p>
                }
              </div>
            )}
          </div>
          <div className="flex justify-between gap-4 md:items-center flex-col md:flex-row w-full">
            <div className="flex flex-col items-start gap-[2px]">
              <h4 className="text-[#6f6f71] text-sm font-normal leading-[14px] tracking-[0.07px]">
                Hate contact forms?
              </h4>
              <a
                href="#"
                className="text-[#171717] text-sm font-normal leading-5 tracking-[0.07px]"
              >
                insynctech@gmail.com
                <span className="text-[#6f6f71]">(click me)</span>
              </a>
            </div>
            <button
              type="submit"
              className="py-5 text-base text-[#F7F7F7] flex items-center  justify-center hover:bg-[#2f3235] gap-1.5 px-6 bg-[#212529] rounded-lg"
            >
              Send message
              {loading ? (
                <svg
                  width="45"
                  height="45"
                  viewBox="0 0 45 45"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#fff"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                    transform="translate(1 1)"
                    stroke-width="2"
                  >
                    <circle cx="22" cy="22" r="6" stroke-opacity="0">
                      <animate
                        attributeName="r"
                        begin="1.5s"
                        dur="3s"
                        values="6;22"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="stroke-opacity"
                        begin="1.5s"
                        dur="3s"
                        values="1;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="stroke-width"
                        begin="1.5s"
                        dur="3s"
                        values="2;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="22" cy="22" r="6" stroke-opacity="0">
                      <animate
                        attributeName="r"
                        begin="3s"
                        dur="3s"
                        values="6;22"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="stroke-opacity"
                        begin="3s"
                        dur="3s"
                        values="1;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="stroke-width"
                        begin="3s"
                        dur="3s"
                        values="2;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="22" cy="22" r="8">
                      <animate
                        attributeName="r"
                        begin="0s"
                        dur="1.5s"
                        values="6;1;2;3;4;5;6"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M3 8.5H12M12 8.5L8.5 4.5M12 8.5L8.5 12.5"
                    stroke="#F7F7F7"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M2.7998 8.10039L4.6998 8.20039L6.6998 8.40039H11.8998M8.3998 4.40039L8.6998 5.20039L9.0998 5.80039L9.6998 6.40039L10.0998 6.60039L10.3998 6.90039L10.9998 7.20039C11.3998 7.50039 11.9998 7.80039 11.9998 8.40039C11.9998 9.10039 11.3998 9.60039 10.9998 10.1004L9.7998 11.5004L8.8998 12.1004L8.3998 12.6004"
                    stroke="#F7F7F7"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default Form;

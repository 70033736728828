import React from "react";

function ThanksForSUBMISSIONAMIGOS({
  setFormSubmitted,
}: {
  setFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="h-[100vh] sm:w-[600px] mx-auto w-full flex flex-col items-center justify-center gap-8  p-6">
      <div className="flex flex-col gap-6 w-full">
        <div className="flex flex-col items-center gap-[18px] w-full">
          <h4 className="text-[#212529] text-center text-4xl sm:text-5xl leading-[48px] font-Anton">
            Thanks For <br /> SUBMISSION AMIGOS!
          </h4>
          <p className="text-[#6c75d] text-center font-light leading-[18px] ">
            Submission Successful!! We are excited to have a chat on your
            awesome Project. MarkBuzzian’s will personally contact you within
            next 24 Hours. Excited !!
          </p>
        </div>
        <button
          onClick={() => setFormSubmitted(false)}
          className="rounded-lg flex text-[#f7f7f7] text-center font-medium leading-4 justify-center items-center py-5 px-6 gap-[6px] bg-[#fc361c]"
        >
          Back To Home
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M3.9751 8.5H12.9751M12.9751 8.5L9.4751 4.5M12.9751 8.5L9.4751 12.5"
              stroke="#F7F7F7"
              strokeWidth="1.2"
            />
            <path
              d="M3.7749 8.10039L5.6749 8.20039L7.6749 8.40039H12.8749M9.3749 4.40039L9.6749 5.20039L10.0749 5.80039L10.6749 6.40039L11.0749 6.60039L11.3749 6.90039L11.9749 7.20039C12.3749 7.50039 12.9749 7.80039 12.9749 8.40039C12.9749 9.10039 12.3749 9.60039 11.9749 10.1004L10.7749 11.5004L9.8749 12.1004L9.3749 12.6004"
              stroke="#F7F7F7"
              strokeWidth="1.2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
      <div className="flex justify-between gap-4 md:items-center flex-col md:flex-row w-full">
        <div className="flex flex-col items-start gap-[2px]">
          <h4 className="text-[#6f6f71] text-sm font-normal leading-[14px] tracking-[0.07px]">
            Urgent??
          </h4>
          <a
            href="#"
            className="text-[#171717] text-sm font-normal leading-5 tracking-[0.07px]"
          >
            Contact Now
            <span className="text-[#6f6f71]">(click me)</span>
          </a>
        </div>
        {/* <TerriaryBtn name="Send message" /> */}
      </div>
    </div>
  );
}

export default ThanksForSUBMISSIONAMIGOS;
